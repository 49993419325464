<template>
    <div class="dropdown" ref="dropdown">
      <!-- Header shows the selected project or placeholder -->
      <div class="dropdown-header" @click="toggleDropdown">
        <span v-if="selected">
          #{{ selected.projectId }} - {{ selected.projectName }}
        </span>
        <span v-else>Select a project</span>
        <i class="arrow" :class="{ open: isOpen }"></i>
      </div>
      <!-- Dropdown panel with search input and filtered options -->
      <div v-if="isOpen" class="dropdown-panel -mt-12 ">
        <input
          type="text"
          v-model="searchQuery"
          placeholder="Search..."
          class="dropdown-search outline-none"
        />
        <ul class="dropdown-list">
          <li
            v-for="option in filteredOptions"
            :key="option.projectId"
            @click="selectOption(option)"
            class="dropdown-item"
          >
            #{{ option.projectId }} - {{ option.projectName }}
          </li>
          <li v-if="!filteredOptions.length" class="dropdown-item no-results">
            No results found.
          </li>
        </ul>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "DropdownSearch",
    props: {
      options: {
        type: Array,
        required: true
      },
      // The v-model value is the projectId (number or string)
      value: {
        type: [Number, String],
        default: null
      }
    },
    data() {
      return {
        isOpen: false,
        searchQuery: ""
      };
    },
    computed: {
      // Find the full project object based on the passed projectId value
      selected() {
        return this.options.find(
          o => Number(o.projectId) === Number(this.value)
        ) || null;
      },
      filteredOptions() {
        if (!this.searchQuery) {
          return this.options;
        }
        const q = this.searchQuery.toLowerCase();
        return this.options.filter(o =>
          String(o.projectId).toLowerCase().includes(q) ||
          (o.projectName && o.projectName.toLowerCase().includes(q))
        );
      }
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
        if (!this.isOpen) {
          this.searchQuery = "";
        }
      },
      selectOption(option) {
        // Emit the option's projectId as the new value
        this.$emit("input", option.projectId);
        this.isOpen = false;
        this.searchQuery = "";
      },
      handleClickOutside(e) {
        if (!this.$refs.dropdown.contains(e.target)) {
          this.isOpen = false;
          this.searchQuery = "";
        }
      }
    },
    mounted() {
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.handleClickOutside);
    }
  };
  </script>
  
  <style scoped>
  .dropdown {
    position: relative;
    width: 100%;
    max-width: 300px;
    user-select: none;
  }
  .dropdown-header {
    border: 1px solid #ccc;
    padding: 0.5rem;
    background: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .arrow {
    border: solid #333;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    transition: transform 0.2s ease;
  }
  .arrow.open {
    transform: rotate(-135deg);
  }
  .dropdown-panel {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background: #fff;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
  }
  .dropdown-search {
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
  }
  .dropdown-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .dropdown-item {
    padding: 0.5rem;
    cursor: pointer;
  }
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  .no-results {
    text-align: center;
    color: #999;
  }
  </style>
  
<template>
  <div class="container mx-auto">
    <nav-menu />

   

    <!-- Tab Navigation -->
    <div class="flex border-b">
      <button :class="['p-4', activeTab === 'leads' ? 'border-b-2 border-blue-500' : 'text-gray-500']"
        @click="activeTab = 'leads'">
        Leads
      </button>
      <button :class="['p-4', activeTab === 'reminders' ? 'border-b-2 border-blue-500' : 'text-gray-500']"
        @click="activeTab = 'reminders'">
        Reminders
      </button>
    </div>

     <!-- Stat Tile -->
     <div v-if="activeTab === 'leads'" class="p-4">
      <div class="stat-tile p-4 bg-gray-100 mt-4 rounded">
        <span class="font-bold">Total Meters:</span>
        <span class="ml-2">{{ totalMeters }}</span>
      </div>
    </div>

    <!-- Create Lead Button -->
    <div v-if="activeTab === 'leads'" class="w-full flex justify-between items-center p-4">

      
      <div >
   <div class="flex space-x-4">
    <select v-model="filterLeads" class="w-full h-12 border border-gray-200 px-2 rounded">
      <option value="all">Show All</option>
      <option value="pending">Pending</option>
      <option value="lost">Lost</option>
      <option value="won">Won</option>
    </select>

    <!-- Region Filter -->
    <select v-model="filterRegion" class="w-48 h-12 border border-gray-200 px-2 rounded">
          <option value="all">All Regions</option>
          <option v-for="region in uniqueRegions" :key="region" :value="region">{{ region }}</option>
        </select>

        <!-- Customer Filter -->
        <select v-model="filterCustomer" class="w-48 h-12 border border-gray-200 px-2 rounded">
          <option value="all">All Customers</option>
          <option v-for="customer in uniqueCustomers" :key="customer" :value="customer">{{ customer }}</option>
        </select>
   </div>
  </div>
  <div class="flex justify-between items-center">
    <outlook class="mr-2" />
    <button @click="openModal(null)" class="bg-primary whitespace-nowrap text-white px-4 py-2 rounded">
      Create Lead
    </button>
  </div>


    </div>

    

    <!-- Leads Table (Desktop) -->
    <div v-if="activeTab === 'leads'" class="w-full overflow-x-auto hidden md:block p-4">
      <table class="table-auto w-full border-collapse border border-gray-300">
        <thead>
          <tr class="bg-gray-800 text-white">
            <th class="border border-gray-300 px-4 py-2">Client</th>
            <!-- Enable sorting on Customer -->
            <th class="border border-gray-300 px-4 py-2 cursor-pointer" @click="setSort('customer')">
              Customer
              <span v-if="sortColumn==='customer'">{{ sortOrder==='asc' ? '▲' : '▼' }}</span>
            </th>
            <th class="border border-gray-300 px-4 py-2">Address</th>
            <!-- Enable sorting on Region -->
            <th class="border border-gray-300 px-4 py-2 cursor-pointer" @click="setSort('region')">
              Region
              <span v-if="sortColumn==='region'">{{ sortOrder==='asc' ? '▲' : '▼' }}</span>
            </th>
            <th class="border border-gray-300 px-4 py-2 whitespace-nowrap">Est Start Date</th>
            <th class="border border-gray-300 px-4 py-2">Meters</th>
            <!-- Combined Name & Phone Column -->
            <th class="border border-gray-300 px-4 py-2">Name &amp; Phone</th>
            <th class="border border-gray-300 px-4 py-2">Quoted Date</th>
            <th class="border border-gray-300 px-4 py-2">Notes</th>
            <th class="border border-gray-300 px-4 py-2">Status</th>
            <th class="border border-gray-300 px-4 py-2">Reminder</th>
            <th class="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <!-- Use sortedLeads which applies filtering and sorting -->
          <tr
            v-for="lead in sortedLeads"
            :key="lead.id"
            :class="{'bg-green-100': lead.status === 'won', 'bg-red-100': lead.status === 'lost'}"
          >
            <td class="border border-gray-300 px-4 py-2">{{ lead.client }}</td>
            <td class="border border-gray-300 px-4 py-2">{{ lead.customer?.company }}</td>
            <td class="border border-gray-300 px-4 py-2 whitespace-nowrap text-xs">{{ lead.formattedAddress }}</td>
            <td class="border border-gray-300 px-4 py-2">{{ lead.region }}</td>
            <td class="border border-gray-300 px-4 py-2 whitespace-nowrap text-xs">{{ lead.estimatedStartDate }}</td>
            <td class="border border-gray-300 px-4 py-2">{{ lead.approxM3 }}</td>
            <td class="border border-gray-300 px-4 py-2">
              <div class="text-sm"><strong>Name:</strong> {{ lead.contact }}</div>
              <div class="text-sm">
                <strong>Phone:</strong>
                <a :href="'tel:' + lead.phone" class="text-blue-500 hover:underline">{{ lead.phone }}</a>
              </div>
            </td>
            <td class="border border-gray-300 px-4 py-2 text-xs whitespace-nowrap">{{ $moment(lead.created.seconds * 1000).format("DD-MMM") }}</td>
            <td class="border border-gray-300 px-4 py-2">
              <div class="w-96 text-xs text-gray-700 text-left">{{ lead.information }}</div>
            </td>
            <td class="border border-gray-300 px-4 py-2">
              <select
                v-model="lead.status"
                @change="handleStatusChange(lead)"
                class="border rounded px-2 py-1"
              >
                <option value="pending">Pending</option>
                <option value="won">Won</option>
                <option value="lost">Lost</option>
              </select>
            </td>
            <td class="border border-gray-300 px-4 py-2">
              <button
                @click="openReminderModal(lead)"
                class="whitespace-nowrap bg-yellow-500 text-white px-2 py-1 rounded"
              >
                Add Reminder
              </button>
            </td>
            <td class="border border-gray-300 px-4 py-2">
              <div class="flex space-x-2">
                <button @click="openModal(lead)" class="bg-blue-500 text-white px-2 py-1 rounded">
                  Edit
                </button>
                <button @click="confirmRemoveLead(lead)" class="bg-red-500 text-white px-2 py-1 rounded">
                  Delete
                </button>
                <button
                  @click="openAddProjectModal(lead)"
                  class="whitespace-nowrap bg-green-500 text-white px-2 py-1 rounded"
                >
                  Add Project
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>


    <!-- Leads Cards (Mobile) -->
    <div v-if="activeTab === 'leads'" class="block md:hidden p-4">
  <div v-for="lead in leads" :key="lead.id" class="border rounded-lg p-4 mb-4 shadow-sm">
    <h3 class="font-bold text-lg mb-2">{{ lead.projectName }}</h3>
    <p class="text-sm text-gray-600 mb-1">Client: {{ lead.client }}</p>
    <p class="text-sm text-gray-600 mb-1">Customer: {{ lead.customer?.company }}</p>
    <p class="text-sm text-gray-600 mb-1">Phone: 
      <a :href="'tel:'+ lead.phone" class="text-blue-500 underline">{{ lead.phone }}</a>
    </p>
    <p class="text-sm text-gray-600 mb-1">Email: 
      <a :href="'mailto:'+ lead.email" class="text-blue-500 underline">{{ lead.email }}</a>
    </p>
    <p class="text-sm text-gray-600 mb-1">Address: {{ lead.formattedAddress }}</p>
    <p class="text-sm text-gray-600 mb-1">Est Start Date: {{ lead.estimatedStartDate }}</p>
    <p class="text-sm text-gray-600 mb-1">Meters: {{ lead.approxM3 }}</p>
    <p class="text-sm text-gray-600 mb-2">Notes: {{ lead.information }}</p>

    <div class="mb-2">
      <label class="block text-sm text-gray-600 mb-1">Status:</label>
      <select v-model="lead.status" @change="handleStatusChange(lead)" class="border rounded px-2 py-1 w-full">
        <option value="pending">Pending</option>
        <option value="won">Won</option>
        <option value="lost">Lost</option>
      </select>
    </div>

    <div class="flex flex-wrap items-center mt-2 space-x-2">
      <button @click="openModal(lead)" class="bg-blue-500 text-white px-3 py-1 rounded">Edit</button>
      <button @click="openReminderModal(lead)" class="bg-yellow-500 text-white px-3 py-1 rounded">Add Reminder</button>
      <button @click="confirmRemoveLead(lead)" class="bg-red-500 text-white px-3 py-1 rounded">Delete</button>
      <button @click="openAddProjectModal(lead)" class="bg-green-500 text-white px-3 py-1 rounded">Add Project</button>
    </div>
  </div>
</div>

    <!-- Reminders Tab -->
    <div v-if="activeTab === 'reminders'">
      <div class="p-4">
        <div class="w-full hidden md:flex">
        <table class=" table-auto w-full border-collapse border border-gray-300">
          <thead>
            <tr class="bg-gray-200">
              <th class="border border-gray-300 px-4 py-2">Lead</th>
              <th class="border border-gray-300 px-4 py-2">Job Details</th>
              <th class="border border-gray-300 px-4 py-2">Reminder Date</th>
              <th class="border border-gray-300 px-4 py-2">Reminder</th>
              <th class="border border-gray-300 px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="reminder in reminders" :key="reminder.id">
              <td class="border border-gray-300 px-4 py-2 text-left">
                <h3  class="font-bold text-lg">{{ reminder.lead.contact }}</h3>
                <h3  class="font-normal text-xs text-primary">{{ reminder.lead.customer.company }}</h3>
                <a :href="'mailto:' + reminder.lead.email"  class="underline font-normal text-xs text-blue-500">{{ reminder.lead.email }}</a>
                <a :href="'tel:' + reminder.lead.phone"  class="ml-4 underline font-normal text-xs text-blue-500">{{ reminder.lead.phone }}</a>
              </td>
              <td class="border border-gray-300 px-4 py-2 text-xs text-left">
                <div><strong>Est start:</strong> {{ reminder.lead.estimatedStartDate }}</div>
                <div><strong>Address:</strong> {{ reminder.lead.formattedAddress }}</div>
                <div><strong>approxM3:</strong> {{ reminder.lead.approxM3 }}</div>
              </td>
              <td class="border border-gray-300 px-4 py-2 text-xs "><div 
    :class="{'bg-red-500 text-white': isPastDate(reminder.date), 'bg-gray-100': !isPastDate(reminder.date)}" 
    class="rounded p-2">
    {{ reminder.date }}
  </div></td>
              <td class="border border-gray-300 px-4 py-2 w-96 text-xs text-left">{{ reminder.subject }}</td>
              <td class="border border-gray-300 px-4 py-2">
                <button @click="confirmRemoveReminder(reminder)" class="text-red-500 text-sm bg-red-100 rounded py-2 hover:bg-red-600 hover:text-white px-3">Remove</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

        <div v-if="activeTab === 'reminders'" class="block md:hidden p-4">
  <div v-for="reminder in reminders" :key="reminder.id" class="border rounded-lg p-4 mb-4 shadow-sm">
    <h3 class="font-bold text-lg mb-2">Reminder for: {{ reminder.leadName }}</h3>
    <p class="text-sm text-gray-600 mb-1">Date: {{ reminder.date }}</p>
    <p class="text-sm text-gray-600 mb-2">Subject: {{ reminder.subject }}</p>
    <div class="flex items-center space-x-2">
      <button @click="confirmRemoveReminder(reminder)" class="bg-red-500 text-white px-3 py-1 rounded">Remove</button>
    </div>
  </div>
</div>
      </div>
    </div>

    <!-- Modal for Add Reminder -->
    <div v-if="showReminderModal" class="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center">
      <div class="bg-white rounded-lg shadow-lg p-10 w-96 h-2/3 overflow-y-auto">
        <h2 class="text-xl font-bold mb-4">Add Reminder</h2>
        <div class="w-full mb-4">
          <label class="block text-left header text-xs mb-2">Reminder Date</label>
          <flat-pickr class="text-xs w-full bg-gray-100 h-12 rounded px-3 mb-2" :config="dateConfig"
            v-model="reminder.date"></flat-pickr>
        </div>
        <div class="w-full mb-4">
          <label class="block text-left header text-xs mb-2">Subject</label>
          <input v-model="reminder.subject" type="text" class="w-full p-2 border rounded" />
        </div>
        <button @click="saveReminder()" class="bg-yellow-500 py-4 text-white px-4 py-2 rounded w-full">
          Save Reminder
        </button>
        <button @click="closeReminderModal" class="mt-2 text-red-500 underline w-full text-center">
          Cancel
        </button>
      </div>
    </div>


    <!-- Add Project Modal -->
<div v-if="showAddProjectModal" class="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center">
  
  <div v-if="toggleviewProject" class="relative bg-white rounded-lg shadow-lg p-10 w-96 overflow-y-auto">
    
    <button @click="closeAddProjectModal" class="absolute top-2 right-2 text-gray-500 hover:text-red-500">
      ✖
    </button>

    <div class="text-left"><h2 class="header mb-1">Select a project</h2></div>
    <select v-model="selectedProjectId" class="w-full p-2 h-20 border rounded">
    <option disabled value="">Select a project</option>
    <option v-for="project in filteredProjects" :key="project.id" :value="project.id">
      {{ project.projectName }}
    </option>
  </select>

  <button @click="navigateToProject" class="mt-4 bg-secondary text-white px-4 py-2 rounded w-full">
      View Project
    </button>

     <div class="mt-4 py-4 border-t border-gray-200">
      <div class="text-left"><h2 class="header mb-1">Or would you like to create a new project</h2></div>
      <button @click="toggleviewProject = false" class="bg-primary text-white px-4 py-2 rounded w-full">
      Create a new project
    </button>
     </div>

      </div>

  <div v-if="!toggleviewProject" class="bg-white rounded-lg shadow-lg p-10 w-96 h-2/3 overflow-y-auto">
    <h2 class="text-xl font-bold mb-4">Add Project</h2>
    

    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Client!!</label>
      <select v-model="newProject.client" class="border border-gray-200 rounded flex-grow h-10 bg-white outline-none px-2 text-gray-600 w-full">
        <option :value="c" v-for="c in clients" :key="c">{{ c }}</option>
      </select>
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Customer</label>
      <multiselect label="company" track-by="company" @search-change="searchCustomers" :loading="loadingSelect"
        v-model="newProject.customerId" :options="customers" :optionsLimit="300000"></multiselect>
    </div>

    <!-- Project Form (Prepopulated with Lead Properties) -->
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Project Name</label>
      <input v-model="newProject.projectName" type="text" class="w-full p-2 border rounded" />
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Address</label>
      <vue-google-autocomplete
        :options="autocompleteOptions"
        id="project-map"
        v-model="newProject.formattedAddress"
        country="au"
        ref="projectAutocomplete"
        v-on:placechanged="getProjectAddressData"
        placeholder="Project Address"
        classname="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
      ></vue-google-autocomplete>
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Estimated Start Date</label>
      <flat-pickr v-model="newProject.estimatedStartDate" :config="config" class="w-full bg-gray-100 h-12 rounded px-3" />
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Estimated End Date</label>
      <flat-pickr v-model="newProject.estimatedEndDate" :config="config" class="w-full bg-gray-100 h-12 rounded px-3" />
    </div>
    
    <!-- Add Remaining Fields -->
    <div v-for="field in newProjectFields" :key="field.key" class="w-full mb-4">
      <label :for="field.key" class="block text-left header text-xs mb-2">{{ field.label }}</label>
      <input v-model="newProject[field.key]" :type="field.type" class="w-full p-2 border rounded" />
    </div>

    <button @click="saveProject" class="bg-blue-500 text-white px-4 py-2 rounded w-full">
      Save Project
    </button>
    <button @click="closeAddProjectModal" class="mt-2 text-red-500 underline w-full text-center">
      Cancel
    </button>
  </div>
</div>



    <!-- Modal -->
    <div v-if="showModal" class="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center">
     
 <!-- Project form  -->
      <div  class="bg-white rounded-lg shadow-lg p-10 relative w-96 h-2/3 overflow-y-auto">
   
        <button @click="showModal = false" class="absolute top-2 right-2 text-gray-500 hover:text-red-500">
      ✖
    </button>

        <h2 class="text-xl font-bold mb-4">
      {{ editingLead ? 'Edit Lead' : 'Create Lead' }}
    </h2>
    <!-- Form Fields -->
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Client</label>
      <select v-model="lead.client" class="rounded flex-grow h-10 bg-white border border-gray-200 outline-none px-2 text-gray-600 w-full">
        <option :value="c" v-for="c in clients" :key="c">{{ c }}</option>
      </select>
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Customer</label>
     <div class="flex space-x-2">
      <div class="flex-grow" v-if="showNewClient">
        <input v-model="newClient" type="text" class="w-full p-2 border rounded" />
        </div>
      <div class="flex-grow" v-if="!showNewClient">
        <multiselect label="company" track-by="company" @search-change="searchCustomers" :loading="loadingSelect"
        v-model="lead.customer" :options="customers" :optionsLimit="300000"></multiselect>
      </div>
      <div class="flex-shrink" v-if="!showNewClient">
        <button @click="showNewClient = true" class="bg-blue-500 text-white px-4 py-2 rounded w-full">
      +
    </button>
      </div>
      <div class="flex-shrink" v-if="showNewClient">
        <button @click="showNewClient = false" class="bg-red-100 text-red-600 px-4 py-2 rounded w-full">
      x
    </button>
      </div>
     </div>
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Contact</label>
      <input v-model="lead.contact" type="text" class="w-full p-2 border rounded" />
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Phone</label>
      <input v-model="lead.phone" type="text" class="w-full p-2 border rounded" />
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Email</label>
      <input v-model="lead.email" type="text" class="w-full p-2 border rounded" />
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Address</label>
      <vue-google-autocomplete
        :options="autocompleteOptions"
        id="map"
        v-model="lead.formattedAddress"
        country="au"
        ref="autocomplete"
        v-on:placechanged="getAddressData"
        classname="rounded h-10 bg-white w-full outline-none px-2 text-gray-600"
        placeholder="Project Address"
      ></vue-google-autocomplete>
    </div>

    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Region</label>
      <input v-model="lead.region" type="text" class="w-full p-2 border rounded" />
    </div>

    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Estimated Start Date</label>
      <flat-pickr class="text-xs w-full bg-gray-100 h-12 rounded px-3 mb-2" :config="config"
        v-model="lead.estimatedStartDate"></flat-pickr>
    </div>
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Estimated End Date</label>
      <flat-pickr class="text-xs w-full bg-gray-100 h-12 rounded px-3 mb-2" :config="config"
        v-model="lead.estimatedEndDate"></flat-pickr>
    </div>
   
    <!-- <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">PO Number</label>
      <input v-model="lead.poNumber" type="text" class="w-full p-2 border rounded" />
    </div> -->
    
    <!-- <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Call Frequency</label>
      <input v-model="lead.callFrequency" type="text" class="w-full p-2 border rounded" />
    </div> -->
    <!-- <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Project Type</label>
      <input v-model="lead.projectType" type="text" class="w-full p-2 border rounded" />
    </div> -->
    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Approx M3</label>
      <input v-model="lead.approxM3" type="text" class="w-full p-2 border rounded" />
    </div>

   

    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Additional Information</label>
      <textarea v-model="lead.information" class="w-full p-2 border rounded"></textarea>
    </div>

    <div class="w-full mb-4">
      <label class="block text-left header text-xs mb-2">Status</label>
      <select v-model="lead.status" @change="handleStatusChange(lead)" class="w-full p-2 border rounded">
        <option value="pending">Pending</option>
        <option value="won">Won</option>
        <option value="lost">Lost</option>
      </select>
    </div>

    <button @click="saveLead" class="bg-blue-500 text-white px-4 py-2 rounded w-full">
      {{ editingLead ? 'Update Lead' : 'Create Lead' }}
    </button>
    <button @click="closeModal" class="mt-2 text-red-500 underline w-full text-center">
      Cancel
    </button>
  </div>
</div>

<div v-if="showLossReasonModal" class="fixed inset-0 bg-black bg-opacity-25 flex justify-center items-center">
  <div class="relative bg-white rounded-lg shadow-lg p-10 w-96">
    <button @click="showLossReasonModal = false" class="absolute top-2 right-2 text-gray-500 hover:text-red-500">
      ✖
    </button>
    <h2 class="text-xl font-bold mb-4">Loss Reason</h2>
    <textarea v-model="lossReason" class="w-full p-2 border rounded" placeholder="Enter the reason for loss"></textarea>
    <button @click="saveLossReason" class="bg-red-500 text-white px-4 py-2 rounded w-full mt-4">
      Save Reason
    </button>
  </div>
</div>


  </div>
</template>

<script>
import Outlook from "@/views/Outlook.vue";
import CreateCustomer from "@/components/cards/createCustomer.vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import NavMenu from "@/components/headers/navMenu";
import { bus } from "../main";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {
  putFire,
  postFire,
  getContractors,
  getJobsByProjectId,
  getFire,
  deleteFire,
  getProjects,
  getLatestJob,
  getClients,
  getLeads,
  getCrmReminders,
  setFire,
  getProjectsByClient
} from "../firestore/utils";
import { getTimeStamp, setGeoPoint } from "../firebase";
const geofire = require("geofire-common");
import Multiselect from "vue-multiselect";
import { auth } from "../firebase";
import axios from 'axios';
import { collection } from "firebase/firestore";

export default {
  components: {
    Multiselect,
    VueGoogleAutocomplete,
    flatPickr,
    Outlook,
    Datetime,
    NavMenu
  },
  data() {
    return {
      filterRegion: 'all',
      showNewClient: false,
      newClient: "",
      filterLeads: "all",
      filterCustomer: 'all',
      // New properties for sorting
      sortColumn: "",
      sortOrder: "asc",
      lossReason: "",
      showLossReasonModal: false,
      toggleviewProject: true,
      showAddProjectModal: false,
      selectedLead: null,
      selectedProjectId: "",
      projects: [],
      newProject: {
        client: null,
        customerId: null,
        projectName: "",
        formattedAddress: "",
        address: null,
        estimatedStartDate: new Date(),
        estimatedEndDate: new Date(),
        active: true,
      },
      newProjectFields: [
        { key: "poNumber", label: "PO Number", type: "text" },
        { key: "projectType", label: "Project Type", type: "text" },
        { key: "callFrequency", label: "Call Frequency", type: "text" },
        { key: "contact", label: "Contact", type: "text" },
        { key: "email", label: "Email", type: "email" },
        { key: "phone", label: "Phone", type: "text" },
        { key: "company", label: "Company", type: "text" },
      ],
      showReminderModal: false,
      activeTab: 'leads',
      config: {
        enableTime: true,
        altFormat: "d-m-Y H:i",
        altInput: true,
        allowInput: false,
      },
      reminder: {
        leadId: null,
        leadName: "",
        date: new Date(),
        subject: "",
      },
      loading: true,
      clients: [],
      customers: [],
      showModal: false,
      editingLead: null,
      leads: [],
      lead: {
        client: null,
        customer: null,
        projectName: "",
        projectType: "",
        poNumber: "",
        contact: "",
        approxM3: "",
        status: "",
        phone: "",
        email: "",
        callFrequency: "",
        projectType: "",
        information: "",
        formattedAddress: "",
        address: null,
        region: "",
        estimatedStartDate: new Date(),
        estimatedEndDate: new Date(),
        quotedDate: new Date(),
        created: null,
        geohash: null,
        geopoint: null
      },
      autocompleteOptions: {
        types: ["geocode"],
      },
      overrideAddress: "",
      myAddressData: null,
      editedAddress: false,
      loadingSelect: false,
      dateConfig: {
        enableTime: true,
        altFormat: "d-m-Y H:i",
      },
    };
  },
  computed: {
    // Combine filtering and sorting into one computed property
    sortedLeads() {
      let leads = this.leads;
      if (this.filterLeads !== "all") {
        leads = leads.filter(lead => lead.status === this.filterLeads);
      }
      if (this.filterRegion !== "all") {
        leads = leads.filter(lead => lead.region === this.filterRegion);
      }
      if (this.filterCustomer !== "all") {
        leads = leads.filter(lead => lead.customer && lead.customer.company === this.filterCustomer);
      }
      if (this.sortColumn) {
        leads = leads.slice().sort((a, b) => {
          let aValue = "";
          let bValue = "";
          if (this.sortColumn === 'region') {
            aValue = a.region ? a.region.toLowerCase() : "";
            bValue = b.region ? b.region.toLowerCase() : "";
          } else if (this.sortColumn === 'customer') {
            aValue = a.customer && a.customer.company ? a.customer.company.toLowerCase() : "";
            bValue = b.customer && b.customer.company ? b.customer.company.toLowerCase() : "";
          }
          if (aValue < bValue) return this.sortOrder === 'asc' ? -1 : 1;
          if (aValue > bValue) return this.sortOrder === 'asc' ? 1 : -1;
          return 0;
        });
      }
      return leads;
    },
    // Sum the approxM3 (converted to number) for all visible leads
    totalMeters() {
      return this.sortedLeads.reduce((acc, lead) => {
        return acc + (Number(lead.approxM3) || 0);
      }, 0);
    },
    // Create a list of unique regions from all leads (change to use sortedLeads if desired)
    uniqueRegions() {
      const regions = this.leads
        .map(lead => lead.region)
        .filter(region => region && region.trim() !== "");
      return Array.from(new Set(regions));
    },
    uniqueCustomers() {
      const customers = this.leads
        .map(lead => lead.customer && lead.customer.company)
        .filter(customer => customer && customer.trim() !== "");
      return Array.from(new Set(customers));
    },
    // If you still need the original filteredLeads, you can keep it here.
    filteredLeads() {
      return this.filterLeads === "all"
        ? this.leads
        : this.leads.filter(lead => lead.status === this.filterLeads);
    },
  },
  methods: {
    // Method to set/toggle sorting
    setSort(column) {
      if (this.sortColumn === column) {
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortOrder = 'asc';
      }
    },
    isPastDate(date) {
      return this.$moment(date).isBefore(this.$moment(), 'day'); 
    },
    async handleStatusChange(lead) {
      if (lead.status === "Won") {
        this.openAddProjectModal(lead);
      } else if (lead.status === "Lost") {
        this.openLossReasonModal(lead);
      } else {
        await putFire({ collection: "leads", doc: lead.id, body: { status: lead.status } });
      }
    },
    openLossReasonModal(lead) {
      this.selectedLead = lead;
      this.showLossReasonModal = true;
    },
    async saveLossReason() {
      if (this.lossReason) {
        await putFire({
          collection: "leads",
          doc: this.selectedLead.id,
          body: { status: "Lost", lossReason: this.lossReason },
        });
        this.showLossReasonModal = false;
        this.lossReason = "";
      }
    },
    async openAddProjectModal(lead) {
      this.selectedLead = lead;
      this.newProject = {
        client: lead.client,
        customerId: lead.customer,
        projectName: lead.projectName,
        formattedAddress: lead.formattedAddress || "",
        address: lead.address || null,
        region: lead.region || "",
        estimatedStartDate: new Date(),
        estimatedEndDate: new Date(),
        poNumber: lead.poNumber || "",
        projectType: lead.projectType || "",
        callFrequency: lead.callFrequency || "",
        contact: lead.contact || "",
        email: lead.email || "",
        phone: lead.phone || "",
        company: lead.customer?.company || "",
        active: true,
      };
      await this.fetchProjects();
      this.showAddProjectModal = true;
    },
    async fetchProjects() {
      try {
        this.projects = await getProjectsByClient({ customerId: this.selectedLead.customer.id });
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    },
    async navigateToProject() {
      if (this.selectedProjectId) {
        const _project = getFire({ collection: "projects", doc: this.selectedProjectId });
        this.saveEmptyJob(_project, this.selectedProjectId);

        const jobs = await getJobsByProjectId(this.selectedProjectId);
        this.$router.push({ name: 'Jobs', query: { jobId: jobs[0].id } });
      }
    },
    closeAddProjectModal() {
      this.showAddProjectModal = false;
      this.toggleviewProject = true;
    },
    getProjectAddressData(addressData) {
      this.newProject.address = addressData;
      this.newProject.formattedAddress = addressData.formatted_address;
    },
    async saveProject() {
      const latestJob = await getLatestJob();
      const newP = latestJob.projectId + 1;
      setFire({
        collection: "projects",
        doc: newP.toString(),
        body: { ...this.newProject, active: true, created: new Date(), customerId: this.selectedLead.customer?.id },
      }).then(() => {
        this.saveEmptyJob(this.newProject, newP);
      }).then(() => {
        this.toggleviewProject = true;
      });
    },
    async saveEmptyJob(lead, newP) {
      if (typeof newP === "string") {
        newP = Number(newP);
        if (isNaN(newP)) {
          throw new Error(`Invalid value for newP: ${newP}. Must be a valid number.`);
        }
      }
      if (typeof newP !== "number") {
        throw new Error(`newP must be a number, but received: ${typeof newP}`);
      }
      let payload = {
        formattedAddress: this.selectedLead.formattedAddress || "",
        client: lead.client || "",
        active: true,
        company: lead.company || "",
        phone: lead.phone || "",
        email: lead.email || "",
        callFrequency: lead.callFrequency || "",
        contact: lead.contact || "",
        poNumber: lead.poNumber || "",
        region: lead.region || "",
        projectType: lead.projectType || "",
        estimatedStartDate: "",
        estimatedEndDate: "",
        customerId: this.selectedLead.customer.id || "",
        address: this.selectedLead.address || null,
        projectId: newP,
        projectName: lead.projectName || "",
        geohash: this.selectedLead.geohash || "",
        geopoint: this.selectedLead.geopoint || null,
        status: "ON-CALL",
        contractor: null,
        meters: this.selectedLead.approxM3,
        mixCode: "",
        accepted: false,
        requirements: "",
        pourLocation:  "",
        notesForTester:  "",
        notes: this.selectedLead.information || "",
        updated: new Date(),
      };

      console.log("payload jobs", payload);

      const _job = await postFire({
        collection: "jobs",
        body: payload,
      });

      this.$router.push({ name: 'Jobs', query: { jobId: _job.id } });
    },
    async confirmRemoveLead(lead) {
      const confirmed = confirm(`Are you sure you want to remove the lead "${lead.projectName}"?`);
      if (confirmed) {
        await this.removeLead(lead);
      }
    },
    async removeLead(lead) {
      try {
        await deleteFire({ collection: "leads", doc: lead.id });
        this.getAllLeads();
        alert("Lead removed successfully.");
      } catch (error) {
        console.error("Error removing lead:", error);
        alert("Failed to remove lead.");
      }
    },
    async confirmRemoveReminder(reminder) {
      const confirmed = confirm(`Are you sure you want to remove the reminder "${reminder.subject}"?`);
      if (confirmed) {
        await this.removeReminder(reminder);
      }
    },
    async removeReminder(reminder) {
      try {
        await deleteFire({ collection: "crmReminders", doc: reminder.id });
        this.getAllReminders();
        alert("Reminder removed successfully.");
      } catch (error) {
        console.error("Error removing reminder:", error);
        alert("Failed to remove reminder.");
      }
    },
    formatToISO(date) {
      if (typeof date === 'string' && !isNaN(Date.parse(date))) {
        return new Date(date).toISOString();
      } else if (date instanceof Date) {
        return date.toISOString();
      } else {
        console.error('Invalid date format:', date);
        return null;
      }
    },
    async createEventCalender(accessToken, reminder) {
      const formattedDate = this.formatToISO(this.reminder.date);
      if (!formattedDate) {
        console.error("Invalid reminder date. Cannot create event.");
        return;
      }
      const notes = `
      Address: ${reminder.lead.address} \n 
      Region: ${reminder.lead.region} \n 
      projectName: ${reminder.lead.projectName} \n 
      approxM3: ${reminder.lead.address} \n 
      client: ${reminder.lead.address} \n 
      contact: ${reminder.lead.contact} \n 
      phone: ${reminder.lead.phone} \n 
      email: ${reminder.lead.email} \n 
      customer: ${reminder.lead.customer.company} \n 
      quotedDate: ${reminder.lead.quotedDate} \n 
      startDate: ${reminder.lead.startDate} \n 
      `;
      const _event = {
        subject: this.reminder.subject,
        start: formattedDate,
        end: formattedDate,
        reminderMinutesBeforeStart: 15,
        accessToken: accessToken,
        notes: notes
      };
      console.log("_event", _event);
      try {
        const response = await axios.post('https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/calendar/event', _event);
        console.log(response.data);
      } catch (error) {
        console.error('Error creating event:', error.message);
      }
    },
    openReminderModal(lead) {
      this.reminder.leadId = lead.id;
      this.reminder.lead = lead;
      this.reminder.leadName = lead.projectName;
      this.showReminderModal = true;
    },
    closeReminderModal() {
      this.showReminderModal = false;
    },
    async saveReminder() {
      const payload = {
        collection: "crmReminders",
        body: { ...this.reminder, created: new Date() },
      };
      await postFire(payload);
      const _user = await this.getCurrentUser();
      console.log("_user", _user);
      const userDoc = await getFire({ collection: "users", doc: _user.uid });
      this.createEventCalender(userDoc.outlookToken, this.reminder);
      this.closeReminderModal();
      this.getAllReminders();
    },
    async getAllReminders() {
      this.reminders = [];
      this.reminders = await getCrmReminders();
    },
    async getCurrentUser() {
      return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe();
          if (user) {
            resolve(user);
          } else {
            reject(new Error("No user is signed in."));
          }
        });
      });
    },
    onInput(a) {
      this.overrideAddress = a.newVal;
      this.editedAddress = true;
    },
    async getAllClients() {
      this.clients = await getClients();
    },
    async getAllLeads() {
      this.leads = await getLeads();
    },
    searchCustomers() {
      this.loadingSelect = true;
      const url = "https://us-central1-ktc-app-33f33.cloudfunctions.net/widgets/search";
      this.axios
        .get(url, {
          params: {
            indexName: "customers",
            filter: null,
            search: this.search,
            twilio: null,
          },
        })
        .then((response) => {
          console.log("response from search", response);
          this.customers = response.data;
          this.loadingSelect = false;
        })
        .catch((e) => {
          this.loadingSelect = false;
          console.log("Error algolia", e);
        });
    },
    getAddressData(addressData) {
      console.log("addressData", addressData);
      this.lead.address = addressData;
      this.myAddressData = addressData;
      this.lead.geohash = geofire.geohashForLocation([addressData.latitude, addressData.longitude]);
      this.lead.geopoint = setGeoPoint({
        latitude: addressData.latitude,
        longitude: addressData.longitude,
      });
      if (addressData && addressData.route && addressData.street_number) {
        this.lead.formattedAddress = `${addressData.street_number} ${addressData.route}, ${addressData.locality}, ${addressData.administrative_area_level_1} ${addressData.country}`;
      }
    },
    loadGoogleMapsScript() {
      return new Promise((resolve, reject) => {
        if (window.google && window.google.maps) {
          resolve();
        } else {
          const script = document.createElement("script");
          script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAgzzzBwnKAnilSbETwkny6ApTz18jlHr4&libraries=places";
          script.async = true;
          script.defer = true;
          script.onload = () => resolve();
          script.onerror = () => reject("Failed to load Google Maps script.");
          document.head.appendChild(script);
        }
      });
    },
    async saveLead() {
      if(this.newClient){
        const cust = await postFire({
          collection: "customers",
          body: {
            company: this.newClient
          }
        })

        this.lead = {...this.lead, customer: {id: cust.id, company: this.newClient}}
      }

      if (this.editingLead) {
        await putFire({
          collection: "leads",
          doc: this.editingLead.id,
          body: { ...this.lead },
        });
      } else {
        await postFire({
          collection: "leads",
          body: { ...this.lead, created: new Date() },
        });
      }
      this.closeModal();
      this.getAllLeads();
    },
    openModal(lead) {
      if (lead) {
        this.editingLead = lead;
        this.lead = { ...lead };
      } else {
        this.editingLead = null;
        this.lead = {
          client: null,
          region: "",
          customer: null,
          projectName: "",
          contact: "",
          approxM3: "",
          status: "",
          created: null,
        };
      }
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
  async mounted() {
    try {
      await this.loadGoogleMapsScript();
      this.loading = false;
    } catch (error) {
      console.error(error);
    }
  },
  created() {
    this.getAllClients();
    this.getAllLeads();
    this.getAllReminders();
  },
};
</script>
